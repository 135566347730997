<template>
  <div>
<b-row class="match-height">
    <b-col cols="12" md="4">    
<b-card title="İletişim"                 title-color="primary"                 subtitle="">
<!-- ACTION SLOT -->
                    <template slot="actions">
                        <feather-icon icon="MoreHorizontalIcon"></feather-icon>
                    </template>
                    
                    <!-- CONTACT BIO -->
                    <b-card-text>
                        <p>Bizi telefonla aradığınızda, Sabah 09:00 Aksam:17:30 Saatleri arasında ofislerimizin açık olduğunu lütfen unutmayın. Saat Dilimi GMT +3:00 İstanbul/TURKEY</p>
                    </b-card-text>

                    <!-- USER BIO -->
                    <b-card-text>
                        <p>Tekstil sektörüne 1995 yılında Herkes İyi Giyinmeli Sloganıyla adım atan  Orhan Giyim. son trendleri, rekabetçi fiyatla ve eksiksiz destek hizmetiyle Türk Tekstil pazarına sunmak amacıyla kurulmuştur. Bay Bayan Çocuk müşterilerine yalın ve direkt süreçlerle ulaşarak, sürekli maliyet avantajı oluşturmayı hedeflemektedir. Böylelikle, kaliteden ödün vermeksizin, en yeni trendleri yansıtan ürün ve çeşitleri, en uygun fiyatla pazara sunabilmektedir.</p>
                    </b-card-text>
                    <!-- OTEHR DATA -->
                    <b-card-text>
                        <h6>Şirket Ünvanı:</h6>
                        <p>Orhan Giyim | Tusse | Orhan ÖZKARA</p>
                    </b-card-text>
                    <b-card-text>
                        <h6>Kuruluş Yılı:</h6>
                        <p>25.05.1995</p>
                    </b-card-text>

                    <b-card-text>
                        <h6>Adres:</h6>
                        <p>Fatih Mahallesi Prof Dr. Aziz Sancar Cad No:34 Küçükçekmece/İstabul -TÜRKİYE</p>
                    </b-card-text>

                    <b-card-text>
                        <h6>Telefon:</h6>
                        <p><a href="tel:+902125985123">+90-212-598-51-23</a></p>
                    </b-card-text>
                    <b-card-text>
                        <h6>Gsm:</h6>
                        <p><a href="tel:+905413029537">+90 541 302 95 37</a></p>
                    </b-card-text>

                    <b-card-text>
                        <h6>Email:</h6>
                        <p><a href="mailto:info@tusse.com.tr"> info@tusse.com.tr</a></p>
                    </b-card-text>

                    <b-card-text>
                        <h6>Vergi Dairesi:</h6>
                        <p>Küçükçekmece</p>
                    </b-card-text>
                    <b-card-text>
                        <h6>VKN:</h6>
                        <p>6980048620</p>
                    </b-card-text>
                    <b-card-text>
                        <h6>Akbank IBAN:</h6>
                        <h5>Orhan ÖZKARA</h5>
                        <i class="fa fa-try"></i><strong></strong>TR55 0020 5000 0912 6412 4000 01<br/>
                    </b-card-text>
                    

                    <b-card-text>
                        <a rel="nofollow" href="https://www.facebook.com/tusse" target="_blank" ><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon" ></feather-icon></a>
                        <a rel="nofollow" href="https://www.twitter.com/tusse" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="TwitterIcon" ></feather-icon></a>
                        <a rel="nofollow" href="https://www.instagram.com/tusse" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon" ></feather-icon></a>
                    </b-card-text>
</b-card>
</b-col>
<b-col cols="12" md="8">
    <b-card title="Bize Ulaşın" header-variant="warning">
      <b-card-text>
        <!-- <feather-icon icon="MapPinIcon"/>
        Mağazalarımız -->
      <div>
        <b-button-group size="sm">
        <b-button variant="outline-danger"  size="sm" @click="sUrl='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10128.92910685887!2d28.76116153140797!3d40.99573900758861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3ef544c9729%3A0x43f8aade723d73d3!2sOrhan%20Giyim!5e0!3m2!1str!2str!4v1615216225889!5m2!1str!2str'"><feather-icon
        icon="HomeIcon"
        class="mr-50"
      />Küçükçekmece</b-button>
        <b-button variant="outline-warning" size="sm" @click="sUrl='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12046.578814588904!2d28.77078300478517!3d40.98926021469256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6d6d981f4c8b5af!2sOrhan%20Giyim!5e0!3m2!1str!2str!4v1615285342873!5m2!1str!2str'"><feather-icon
        icon="HomeIcon"
        class="mr-50"
      />Tepeüstü</b-button>
        <b-button variant="outline-primary" size="sm" @click="sUrl='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12046.578814588904!2d28.77078300478517!3d40.98926021469256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3c31182e8d3%3A0xc2dc2d685c2595db!2sOrhan%20Giyim%20Ma%C4%9Fazalar%C4%B1!5e0!3m2!1str!2str!4v1615285409702!5m2!1str!2str'"><feather-icon
        icon="HomeIcon"
        class="mr-50"
      />Cennet</b-button>
        </b-button-group>
      </div>
      </b-card-text>
      <div>
        <section id="google_map_5" class="map_area-02">
          	<iframe :src="sUrl" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </section>
      </div>

    </b-card>
    <b-card title="Bize Sorun">
     
        <b-form>
            <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
            >
                <b-form-input
                    id="input-1"
                    v-model="form.mail"
                    type="email"
                    placeholder="Email"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Adınız:" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="form.name"
                    placeholder="Adınız"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" label="Telefon:" label-for="input-3">
                <b-form-input
                    id="input-3"
                    v-model="form.tel"
                    type="number"
                    placeholder="Telefon"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-4" label="Mesaj:" label-for="input-4">
                <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder="Mesaj"
                    required
                    rows="4"
                    max-rows="6"
                ></b-form-textarea>
            </b-form-group>
             <b-card-text>
        İp Adresiniz: {{ip}}
      </b-card-text>
            <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                v-model="form.checked"
                id="checkboxes-4"
                :aria-describedby="ariaDescribedby"
            >
            <b-form-checkbox :value="true"><b-link :to="{name:'onay-metni'}">KVKK Onaylıyorum</b-link></b-form-checkbox>
            </b-form-checkbox-group>
      </b-form-group>
            <div class="demo-inline-spacing">
            <b-button variant="primary" :disabled="!form.checked&&(form.name||form.tel||form.description||form.mail)" @click="confirmButtonColor">Gönder</b-button>
            <b-button type="reset" variant="outline-warning" v-ripple.400="'rgba(255, 255, 255, 0.15)'" >Vazgeç</b-button>
            </div>
            
        </b-form>
    </b-card>
    </b-col>
    </b-row>

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Sorularınızmı Var?</h2>
          <b-card-text class="mb-3">
            Her zaman bizimle iletişime geçebilirsiniz. Size kısa sürede cevap vereceğiz!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4><a href="tel:+902125985123">+90 (212) 598 51 23</a></h4>
            <span class="text-body">Yardımcı olmaktan her zaman mutluluk duyarız!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4><a href='mailto:info@tusse.com.tr'>info@tusse.com.tr</a></h4>
            <span class="text-body">Daha hızlı yanıt almanın en iyi yolu!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BFormGroup, BFormCheckboxGroup,BInputGroup, BFormInput,BFormCheckbox, BFormTextarea, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar, BButton,BButtonGroup,BLink
} from 'bootstrap-vue'
import axios from 'axios';

import { mapGetters } from "vuex";


export default {
  components: {
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BButton,
    BButtonGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BLink,
    
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
      ip:'',
      sUrl:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10128.92910685887!2d28.76116153140797!3d40.99573900758861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3ef544c9729%3A0x43f8aade723d73d3!2sOrhan%20Giyim!5e0!3m2!1str!2str!4v1615216225889!5m2!1str!2str',
      form: {
          company:'',
          mail: '',
          name: '',
          tel:'',
          description:'',
          subject: 'İletişim',
          food: null,
          checked: false,
        },
      sModuller:'',
      sOnayKodlari:'',
      sent: false,
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  mounted(){
    axios('https://api.ipify.org?format=json').then(response => {this.ip=response.data.ip})
                            

  },
  computed:{

    ...mapGetters("auth", ["getFirmaResult"])

  },
  methods: {
    fetchData() {
      this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => { this.faqData = res.data })
    },
    clear() {
      this.form = {email:'',name:'',phone:'',message:'',checked:false}
    },
    // comfirm button color
    confirmButtonColor() {
      this.$swal({
        title: 'Dikkat',
        text: "Mesajınızı İletmek istediğinize Emin misiniz?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Gönder!',
        cancelButtonText:'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Gönderildi!',
            text: 'Mesajınız Başarıyla Gönderildi',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(()=>{this.gonderMail()})
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'İptal Edildi',
            text: 'Mesaj Gönderimi İptal Edildi',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    gonderMail(){

      event.preventDefault()
      
                //debugger;
                var str="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+this.getFirmaResult.sMail+"</sMail>"+
                      "<sUserName>"+this.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+this.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>string</AuthenticationToken>"+
                      "<sBayii>"+this.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<AuthenticationUser xmlns='http://tempuri.org/' />"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      console.log(str);
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str,headers: {'Content-Type': 'text/xml','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                        //console.log(response);
                        var parseString = require('xml2js').parseString;
                        var xml = response.data
                        var AuthenticationToken =""
                        parseString(xml, function (err, result) {
                          //console.log(result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0]);
                          AuthenticationToken = result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0];
                        });
                      var sFirma = "<p><strong><u>" + this.form.description + ";</u></strong><br><br><br>" + "Firma:<strong>" + this.form.company  + "</strong><br>"+"Adım:<strong>" + this.form.name  + "</strong><br>"+"Mail Adresim:<strong>" + this.form.mail  + "</strong><br>"+"Telefon Numaram:<strong><a href='tel:" + this.form.tel + "'>" + this.form.tel + "</strong></p>";
                      //console.log(sFirma);
                      var ip=window.location.host; 
                      axios('https://api.ipify.org?format=json')
                        .then(response => {
                            ip=response.data.ip
                            console.log(response);
                        
                      var str2="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+this.getFirmaResult.sMail+"</sMail>"+
                      "<sUserName>"+this.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+this.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>"+AuthenticationToken+"</AuthenticationToken>"+
                      "<sBayii>"+this.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<mail_gonder xmlns='http://tempuri.org/'>"+
                      "<sSubject>"+this.form.subject+"</sSubject>"+
                      "<nFirmaID>-4</nFirmaID>"+
                      //"<nFirmaID>"+this.getFirmaResult.nFirmaID+"</nFirmaID>"+
                      "<sKodu></sKodu>"+
                      "<sAciklama></sAciklama>"+
                      "<sAdres1></sAdres1>"+
                      "<sAdres2></sAdres2>"+
                      "<sSemt></sSemt>"+
                      "<sIl></sIl>"+
                      "<telefon></telefon>"+
                      "<sEMail>"+this.form.mail+"</sEMail>"+
                      "<sVergiDairesi></sVergiDairesi>"+
                      "<sVergiNo></sVergiNo>"+
                      "<nAlisverisID>0</nAlisverisID>"+
                      "<lFisNo>0</lFisNo>"+
                      "<sFirma><![CDATA["+  sFirma + "]]></sFirma>"+
                      "<sModuller><![CDATA[<strong>"+this.sModuller+"</strong>]]></sModuller>"+
                      "<sOnayKodlari><![CDATA["+this.sOnayKodlari+"]]></sOnayKodlari>"+
                      "<sOdemeSekli>"+this.form.subject+"</sOdemeSekli>"+
                      "<sIp>"+ip+"</sIp>"+
                      "</mail_gonder>"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      
                      //console.log(str2);
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str2,headers: {'Content-Type': 'text/xml; charset=utf-8','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                          if (response.status==200){
                            console.log(response.statusText);
                            this.clear();
                          }
                          else{
                                console.log(response); 
                          };
                        
                        event.preventDefault();
                        
                      })
                })
                }
                      );

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
